<template>
  <div class="article-classify">
    <!-- 头部 -->
    <div class="header-tool">
      <div class="del" @click="showAddClassBox">
        <img src="../../../assets/images/addClass.png" height="40" alt="">
      </div>
      <div class="del" @click="delClass(0)">
        <img src="../../../assets/images/del.png" alt="">
      </div>
      <div class="refresh">
        <img src="../../../assets/images/refresh.png" alt="">
      </div>
    </div>
    <!-- 表格 -->
    <div class="content">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        stripe
        border=""
        class="tableBox"
      >
        <el-table-column type="selection" width="60" align="center"></el-table-column>
        <el-table-column prop="title" label="分类名称" width="550" align="left" header-align="center"></el-table-column>
        <el-table-column prop="sign" label="分类标识" width="250" align="center"></el-table-column>
        <el-table-column prop="pid" label="层级" width="150" align="center"></el-table-column>
        <el-table-column prop="sort" label="排序" width="150" align="center">
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.sort"
              size="mini"
              maxlength="2"
              onkeyup="value=value.replace(/[^\d]/g,'') "
              @blur="upSort(scope.row)"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button size="mini" type="plain" @click="showAddClassBox(scope.row)">编辑</el-button>
            <el-divider direction="vertical"></el-divider>
            <el-button size="mini" type="plain" @click="delClass(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 添加分类弹出框 -->
    <el-dialog
      :title="title"
      :visible.sync="addClassBox"
      width="30%"
      center
      :close-on-click-modal="false"
      @close="reset"
    >
      <el-form ref="form" :model="form" label-width="80px" size="mini">
        <el-form-item label="分类名称:">
          <el-input v-model.trim="form.title"></el-input>
          <span class="item">注意：唯一标识</span>
        </el-form-item>
        <el-form-item label="分类标识:">
          <el-input v-model.trim="form.sign"></el-input>
          <span class="item">注意：唯一标识</span>
        </el-form-item>
        <el-form-item label="排序:">
          <el-input v-model.trim="form.sort" onkeyup="value=value.replace(/[^\d]/g,'') "></el-input>
          <span class="item">说明：值越大，排名越高</span>
        </el-form-item>
        <el-form-item label="层级:">
          <el-input v-model.trim="form.pid" onkeyup="value=value.replace(/[^\d]/g,'') "></el-input>
          <span class="item">说明：0 为 顶级</span>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addClassBox = false">取 消</el-button>
        <el-button type="primary" @click="addClass">{{btnName}}</el-button>
      </span>
    </el-dialog>
    <!-- 页码 -->
    <div class="page">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="0"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import { reviewartClass, deleteartClass, getartClass } from "@/api/article.js";
export default {
  data() {
    return {
      form: {
        title: "",
        sort: "",
        sign: "",
        pid: "",
        id: 0
      },
      tableData: [],
      multipleSelection: [],
      addClassBox: false,
      title: "创建文章分类",
      page: 0,
      pageSize: 18,
      total: 0,
      btnName: "创 建"
    };
  },
  created() {
    this.loadListData();
  },
  methods: {
    // 页码控制
    handleCurrentChange(val) {
      this.page = val - 1;
      this.loadListData();
    },
    // 全选
    handleSelectionChange(val) {
      this.multipleSelection = val;
      let p = [];
      this.multipleSelection.forEach(v => {
        p.push(v.id);
      });
      this.multipleSelection = p;
    },
    // 显示添加框
    showAddClassBox(row) {
      this.addClassBox = true;
      this.btnName = "创 建";
      this.title = "创建文章分类";
      if (row.id) {
        this.btnName = "修 改";
        this.title = "编辑文章分类信息";
        let { title, sort, sign, pid, id } = row;
        this.form.title = title;
        this.form.sort = sort;
        this.form.sign = sign;
        this.form.pid = pid;
        this.form.id = id;
      } else {
        delete this.form.id;
      }
    },
    // 添加 、 修改类
    addClass() {
      reviewartClass(this.form).then(res => {
        if (res.code == 400200) {
          this.$message({
            showClose: true,
            message: res.msg,
            type: "success"
          });
          this.addClassBox = false;
          this.loadListData();
        }
      });
    },
    // 加载数据
    loadListData() {
      let data = {
        page: this.page,
        pagesize: this.pageSize
      };
      getartClass(data).then(res => {
        console.log(res);

        this.tableData = res.data;
        this.total = res.num * 1;
      });
    },
    // 模态框重置
    reset() {
      this.form.title = "";
      this.form.sort = "";
      this.form.sign = "";
      this.form.pid = "";
      delete this.form.id;
    },
    // 排序更新
    upSort(row) {
      let data = row;
      reviewartClass(data).then(res => {
        if (res.code == 400200) {
          this.$message({
            showClose: true,
            message: res.msg,
            type: "success"
          });
        }
      });
    },
    // 删除
    delClass(id) {
      let ids = "";
      if (id == 0) {
        if (this.multipleSelection.length <= 0) {
          this.$message({
            showClose: true,
            message: "请先勾选要删除的数据",
            type: "warning"
          });
          return;
        }
        ids = this.multipleSelection.toString();
      } else {
        ids = id.toString();
      }
      //
      //  提示 是否删除
      this.$confirm("是否删除已勾选的文章分类?", "提示", {
        confirmButtonText: "删除",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          deleteartClass({ ids }).then(res => {
            if (res.code === 400200) {
              this.$message({
                message: "删除成功！",
                type: "success"
              });
            }
            this.loadListData();
          });
        })
        .catch(() => {});
    }
  }
};
</script>
<style lang="less">
.article-classify {
  // 顶部功能
  .header-tool {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .del {
      width: 100px;
      margin-right: 20px;
      cursor: pointer;
    }
    .refresh {
      width: 100px;
      cursor: pointer;
    }
    .interviewBtn {
      width: 130px;
      margin-right: 20px;
      cursor: pointer;
    }
    img {
      width: 100%;
      pointer-events: none;
    }
  }
  // 筛选
  .filtrate {
    display: flex;
    width: 100%;
    height: 50px;
    background: rgb(217, 242, 247);
    margin-top: 20px;
    .header-title {
      color: rgb(4, 87, 102);
      width: 56px;
      text-align: center;
      border-right: 1px solid rgb(127, 222, 243);
    }
    p {
      line-height: 50px;
      font-size: 14px;
    }
    .header-from {
      padding-left: 10px;
      display: flex;
      align-items: center;
      .el-input__inner {
        width: 100px;
        padding-right: 20px;
      }
      .el-form-item {
        margin-bottom: 0;
      }
      .el-form-item__label {
        color: rgb(4, 87, 102);
      }
      .el-input__icon {
        width: 15px;
      }
      .el-button--mini {
        padding: 5px 10px;
        background: transparent;
        color: rgb(4, 87, 102);
        font-size: 16px;
        &:hover {
          border: 1px solid rgb(105, 105, 105);
        }
      }
    }
  }
  //   表格
  .content {
    position: relative;
    height: calc(~"100% - 220px");
    min-height: 750px;
    margin-top: 20px;
    color: rgb(4, 87, 102);
    th {
      color: rgb(4, 87, 102);
      padding: 0;
      height: 40px;
      line-height: 40px;
      background: rgb(217, 242, 247);
    }
    td {
      padding: 0;
      height: 35px;
      line-height: 35px;
    }
    tr.el-table__row--striped td {
      background: rgb(234, 246, 248);
    }
    .el-button + .el-button {
      margin-left: 0px;
    }
    .el-button--mini {
      padding: 4px 4px;
      background: transparent;
      color: rgb(4, 87, 102);
      font-size: 14px;
      &:hover {
        border: 1px solid rgb(105, 105, 105);
      }
    }
    .no {
      color: green;
    }
    .off {
      color: red;
    }
    .el-input__inner {
      width: 50px;
      padding: 5px;
      text-align: center;
    }
  }
  // 页码
  .page {
    // .el-pagination {
    //   position: absolute;
    //   bottom: 0;
    //   right: 0;
    // }
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
  // 弹出框
  .el-dialog__wrapper {
    .el-input {
      width: 50%;
    }
    .item {
      margin-left: 30px;
    }
  }
}
</style>